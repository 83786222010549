import React, { useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';
import MediaContent from '../components/Give/MediaContent';
import patreonLogo from '../images/patreon-logo.svg';
import DonorboxForm from '../components/NewGive/DonorForm';
import { LJCampaignUrl, LJLanguageCampaignUrl } from '../constants/donorboxcampaign';

const GivePage = (props) => {
  const {
    data: { bgGive, patreonThumb },
  } = props;
  useEffect(() => {
    navigate('/give', { ...props });
  }, []);
  const [activeTabId, setActiveTabId] = useState(1);
  return (
    <BodyClassName className="body-light page-give">
      <DefaultLayoutComponent title="Give">
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner over_lpd-banner bgImage bg-pos-center-top"
            fluid={bgGive.childImageSharp.fluid}
            preserveStackingContext>
            <div className="overlay" />
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="wrapper wrapper-md pd-x-md pb-container">
                <div className="pb-content">
                  <p className="p-xl">
                    {' '}
                    Lineage began as a seed. A small idea packed with big potential. True to its nature, that seed has
                    grown beyond our wildest expectations and has reached people in ways we could never have imagined.
                  </p>
                </div>
              </div>
            </div>
          </BackgroundImage>
          <section className="give-container over_lpd-container bg-dark pd-0">
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="section bg-light give pd-b-0">
                <div className="wrapper wrapper-lg pd-x-md">
                  <h4>Give</h4>
                  <ul className="tab-head give-head method-donation d-flex">
                    {[
                      [1, 'Online Donation'],
                      [2, 'Patreon'],
                      [3, 'US Tax Deductible'],
                      [4, 'Check'],
                    ].map((tabItem) => (
                      <li
                        className={`tab-item method-list ${activeTabId === tabItem[0] ? 'active' : ''}`}
                        key={tabItem[0]}
                        onClick={() => {
                          setActiveTabId(tabItem[0]);
                          window.innerWidth < 768 && window.scrollTo({ top: 1100, behavior: 'smooth' });
                        }}>
                        {tabItem[1]}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="tab-body give-body">
                  <div className={`section tab-content method-1 bg-light pd-y-0 ${activeTabId !== 1 ? 'd-none' : ''}`}>
                    <div className="method-body d-flex justify-content-center align-items-center">
                    <DonorboxForm src={LJCampaignUrl}/>
                    </div>
                  </div>
                  <div className={`section tab-content method-2 bg-light pd-y-0 ${activeTabId !== 2 ? 'd-none' : ''}`}>
                    <div className="method-body">
                      <div className="method-content">
                        <div className="wrapper wrapper-md pd-x-md">
                          <div className="wrapper-sm">
                            <img src={patreonLogo} alt="Patreon" className="method-logo" />
                            <p className="p-lg">
                              Join our Patreon Community by supporting on a monthly basis in return for some exciting
                              rewards including: early access to episodes, google hangout calls, Q&A videos, Colourings
                              Books, DVD’s, T Shirts and more!
                            </p>
                            <a
                              href="https://www.patreon.com/lineagejourney"
                              target="_blank"
                              className="btn btn-md btn-primary-ii">
                              Go To Our Patreon Page
                            </a>
                          </div>
                        </div>
                      </div>
                      <MediaContent thumbnail={patreonThumb} />
                    </div>
                  </div>
                  <div className={`section tab-content method-3 bg-light pd-y-0 ${activeTabId !== 3 ? 'd-none' : ''}`}>
                    <div className="method-body">
                      <div className="method-content">
                        <div className="wrapper wrapper-md pd-x-md">
                          <div className="wrapper-xs">
                            <p className="p-lg">
                              If you live in the US and you want to make a tax deductible donation then follow these 4
                              steps:
                            </p>
                            <ul className="list-counter">
                              <li>
                                Go to <a href="https://www.outpostcenters.org/donate">www.outpostcenters.org/donate</a>
                              </li>
                              <li>Select Ministry - Lineage Journey - Click continue</li>
                              <li>Fill in the rest of the form with the donation amount and personal details.</li>
                            </ul>
                            <span className="list-note">Note: You can make this a recurring donation also*</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`section tab-content method-3 bg-light pd-y-0 ${activeTabId !== 4 ? 'd-none' : ''}`}>
                    <div className="method-body">
                      <div className="method-content">
                        <div className="wrapper wrapper-md pd-x-md">
                          <div className="wrapper-md">
                            <div className="list-wrap mg-b-30">
                              <p className="header">
                                Should you wish to make a donation by check/cheque then please follow these
                                instructions:
                              </p>
                              <ul className="list-counter">
                                <li>Make the check out to ‘Lineage Journey’.</li>
                                <li>
                                  Send it to:
                                  <br /> Lineage Journey
                                  <br />
                                  PO Box 405
                                  <br />
                                  Grass Valley
                                  <br /> CA 95945
                                  <br />
                                  USA
                                </li>
                              </ul>
                              <span className="list-note">Note: This donation will also be tax deductible</span>
                            </div>
                            <div className="list-wrap">
                              <p className="header">
                                Or if you don’t live in the US you can follow the instructions below:
                              </p>
                              <ul className="list-counter">
                                <ul className="list-counter">
                                  <li>Make it out to ‘Lineage Journey’</li>
                                  <li>
                                    Post it to: <br />
                                    Lineage Journey <br />
                                    17 Little Meadow
                                    <br /> Cotgrave
                                    <br /> Nottingham
                                    <br /> NG123PN
                                    <br /> United Kingdom
                                  </li>
                                </ul>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section method-footer method-note">
                  <div className="wrapper wrapper-md pd-x-md">
                    <h6 className="note-title">Thank you so much for supporting Lineage Journey!</h6>
                    <div className="note-text">
                      <p>
                        Lineage Journey is a registered non-profit organisation (in the UK) and relies on crowdfunding
                        and the generous donations of our supporters to produce high quality videos and resources. With
                        an average donation of less than $30, monthly (Patreon) supporters make our work possible
                        through their kindness and generosity. All our videos are available for free, thanks to your
                        help.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  {
    bgGive: file(relativePath: { eq: "bg-give.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    patreonThumb: file(relativePath: { eq: "patreon-thumb.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default GivePage;
